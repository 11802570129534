import { notifyError, notifySuccess } from "~/utils/notifications.js";
import { storeToRefs } from "pinia";
import { accountApiService } from "~/services/accountApiService";
import { useAuthStore } from "~/store/auth.js";
import { form } from "~/validations/loginFormRules";
import { useAccountApi } from "./useAccountApi";
import { showResetPasswordDialog } from "~/utils/dialogActions";

export const useGoogleLoginApi = () => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  const router = useRouter();
  const { googleLogin } = accountApiService();
  const { saveToken } = useAuthStore();
  const { fetchUserInfoApi } = useAccountApi();

  const googleLoginApi = async (credential) => {
    loading.value = true;

    try {
      const response = await googleLogin({
        id_token: credential,
      });

      if (response?.status === 200) {
        await handleSuccessfulLogin(response.data);
      } else {
        notifyDefaultLoginError();
      }
    } catch (err) {
      console.error(err);
      error.value = err?.message || "Google Login error";
      notifyDefaultLoginError();
    } finally {
      loading.value = false;
    }
  };

  const handleSuccessfulLogin = async (data) => {
    await saveToken(data.token);
    await fetchUserInfoApi();
    navigateToRedirectOrDashboard();
  };

  const navigateToRedirectOrDashboard = () => {
    // const redirectPath =
    //   router.currentRoute.value.query.redirect?.toString() ||
    //   "/dashboard/collection";

    const redirectPath = router.currentRoute.value.query.redirect?.toString() || "/";
    router.push(redirectPath);
  };

  const notifyDefaultLoginError = () => {
    notifyError({
      title: "Failed to authenticate!!!",
      text:
        "Sorry, an error occurred while processing your request. Please refresh the page and try again. If the problem persists, please contact our support team for assistance.",
    });
  };

  return {
    data,
    error,
    loading,
    googleLoginApi,
  };
};
